import React from 'react';

const Footer = (props) => {
  return (
    <div className="footer">
      <span>&copy; Vasyl Dzhala, 2018</span>
    </div>
  )
};

export default Footer;
